import { HttpClient } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { Observable } from 'rxjs';

import {
  TContactPackages,
  TSubscription,
} from '@components/pages/profile/profilePages/subscription/core/subscriptionTypes';
import { TBooleanResponse } from '@models/authTypes';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class SubscriptionService {
  #subscription = signal<TSubscription | null>(null);
  subscription = computed(this.#subscription);

  #contactPackages = signal<TContactPackages>([]);
  contactPackages = computed(this.#contactPackages);

  // Без подписки
  isWithoutSubscription = computed(
    () =>
      this.#subscription()?.demo_status.value === 'not_used' &&
      this.#subscription()?.subscription_status.value === 'not_used',
  );

  // Активная подписка или пробный период
  isActiveSubscription = computed(
    () =>
      this.#subscription()?.demo_status.value === 'active' ||
      this.#subscription()?.subscription_status.value === 'active',
  );

  // Пробный период
  isDemoSubscription = computed(
    () => this.#subscription()?.demo_status.value === 'active',
  );

  // Подписка в процессе отмены
  isCancelingSubscription = computed(
    () => this.#subscription()?.subscription_status.value === 'canceling',
  );

  #loadingSubscription = signal<boolean>(true);
  loadingSubscription = computed(this.#loadingSubscription);

  #errorSubscription = signal<string | null>(null);
  errorSubscription = computed(this.#errorSubscription);

  constructor(private http: HttpClient) {}

  // Общая информация о подписке
  loadSubscription(): Observable<TSubscription> {
    return this.http.get<TSubscription>(
      `${environment.getApiVersionUrl('subscription')}`,
    );
  }

  // Список пакетов контактов
  loadContactPackages(): Observable<TContactPackages> {
    return this.http.get<TContactPackages>(
      `${environment.getApiVersionUrl('subscription/contact-packages')}`,
    );
  }

  // Купить пакет контактов
  buyContactPackage(id: number): Observable<TBooleanResponse> {
    return this.http.post<TBooleanResponse>(
      `${environment.getApiVersionUrl(`subscription/contact-packages/${id}/buy`)}`,
      {},
    );
  }

  // Активация пробного периода
  activateDemo(): Observable<TBooleanResponse> {
    return this.http.post<TBooleanResponse>(
      `${environment.getApiVersionUrl('subscription/demo/activate')}`,
      {},
    );
  }

  // Активация подписки
  activateSubscription(): Observable<TBooleanResponse> {
    return this.http.post<TBooleanResponse>(
      `${environment.getApiVersionUrl('subscription/subscription/activate')}`,
      {},
    );
  }

  // Отмена подписки
  cancelSubscription(): Observable<TBooleanResponse> {
    return this.http.put<TBooleanResponse>(
      `${environment.getApiVersionUrl('subscription/subscription/cancel')}`,
      {},
    );
  }

  setSubscription(data: TSubscription | null) {
    this.#subscription.set(data);
  }

  setContactPackages(data: TContactPackages) {
    this.#contactPackages.set(data);
  }
}
