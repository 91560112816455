import { Injectable, inject } from '@angular/core';
import { LOCAL_STORAGE } from '@ng-web-apis/common';

import { TStorageTokens } from '@models/commonTypes';

@Injectable({ providedIn: 'root' })
export class StorageService {
  readonly #storage = inject(LOCAL_STORAGE);

  getItem<T>(key: string): T | null {
    const raw = this.#storage.getItem(key);

    return raw === null ? null : (JSON.parse(raw) as T);
  }

  getTokensItem(key: string): TStorageTokens {
    const storage = this.#storage.getItem(key);

    let data = null;

    try {
      if (storage) {
        data = JSON.parse(storage);
      }
    } catch (error) {
      console.error(error);
    }

    if (!data)
      return {
        accessToken: null,
        refreshToken: null,
      };

    const accessToken = data.accessToken ? encodeURI(data.accessToken) : null;
    const refreshToken = data.refreshToken
      ? encodeURI(data.refreshToken)
      : null;

    return {
      accessToken: accessToken,
      refreshToken: refreshToken,
    };
  }

  setItem<T>(key: string, value: T | null): void {
    const stringified = JSON.stringify(value);
    this.#storage.setItem(key, stringified);
  }

  clearItem(key: string): void {
    this.#storage.removeItem(key);
  }
}
