import { HttpClient } from '@angular/common/http';
import { Injectable, computed, signal } from '@angular/core';
import { Observable, forkJoin } from 'rxjs';

import { TFormattedApiError } from '@utils/formattingApiError';
import { TAnnouncementType } from '@models/announcementTypes';
import {
  TSelectedCategories,
  TSelectedRegions,
  TSettingsCategoriesRequest,
  TSettingsRegionsRequest,
  TToogleCategoriesTreeEntity,
} from './priorityTypes';
import { TBooleanResponse } from '@models/authTypes';
import { NotificationService } from '@services/notification.service';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class PriorityService {
  // Доступные выбранные категории
  #listSelectedCategories = signal<TSelectedCategories>([]);
  listSelectedCategories = computed(this.#listSelectedCategories);

  // Доступные выбранные регионы
  #listSelectedRegions = signal<TSelectedRegions>([]);
  listSelectedRegions = computed(this.#listSelectedRegions);

  #loading = signal<boolean>(false);
  loading = computed(this.#loading);

  #defaultLoading = signal<boolean>(false);
  defaultLoading = computed(this.#defaultLoading);

  #error = signal<string | null>(null);
  error = computed(this.#error);

  constructor(
    private http: HttpClient,
    private notification: NotificationService,
  ) {}

  loadAllLists({
    type,
    isDefaultLoading,
  }: {
    type: TAnnouncementType;
    isDefaultLoading?: boolean;
  }): void {
    this.loadListSelectedCategories({ type, isDefaultLoading });
    this.loadListSelectedRegions(isDefaultLoading);
  }

  // Список доступных выбранных регионов
  loadListSelectedRegions(isDefaultLoading?: boolean): void {
    if (!isDefaultLoading) {
      this.#loading.set(true);
    } else {
      this.#defaultLoading.set(true);
    }

    this.#error.set(null);

    this.http
      .get<TSelectedRegions>(
        `${environment.getApiVersionUrl('user/settings/regions')}`,
      )
      .subscribe({
        next: (response) => {
          this.#listSelectedRegions.set(response);
          if (!isDefaultLoading) {
            this.#loading.set(false);
          } else {
            this.#defaultLoading.set(false);
          }
        },
        error: (error: TFormattedApiError) => {
          this.#error.set(error.formattedErrorMessage);
          this.#loading.set(false);
        },
      });
  }

  // Список доступных выбранных категорий
  loadListSelectedCategories({
    type,
    isDefaultLoading,
  }: {
    type: TAnnouncementType;
    isDefaultLoading?: boolean;
  }): void {
    if (!isDefaultLoading) {
      this.#loading.set(true);
    } else {
      this.#defaultLoading.set(true);
    }
    this.#error.set(null);

    this.http
      .get<TSelectedCategories>(
        `${environment.getApiVersionUrl(`user/settings/categories/${type}`)}`,
      )
      .subscribe({
        next: (response) => {
          this.#listSelectedCategories.set(response);
          if (!isDefaultLoading) {
            this.#loading.set(false);
          } else {
            this.#defaultLoading.set(false);
          }
        },
        error: (error: TFormattedApiError) => {
          this.#error.set(error.formattedErrorMessage);
          this.#loading.set(false);
        },
      });
  }

  // Установка настроек "по умолчанию"
  setDefaults(type: TAnnouncementType) {
    this.#defaultLoading.set(true);

    forkJoin([
      this.setListSelectedRegions({ region_ids: [], is_all_regions: true }),
      this.setListSelectedCategories('sell', []),
      this.setListSelectedCategories('buy', []),
    ]).subscribe({
      next: () => {
        this.#error.set(null);
        this.#defaultLoading.set(false);

        this.loadAllLists({ type, isDefaultLoading: true });
      },
      error: (error: TFormattedApiError) => {
        this.#defaultLoading.set(false);

        if (error.formattedErrorMessage)
          this.notification.error(error.formattedErrorMessage);
      },
    });
  }

  // Настройка отображения объявлений по регионам
  setListSelectedRegions(
    data: TSettingsRegionsRequest,
  ): Observable<TBooleanResponse> {
    return this.http.put<TBooleanResponse>(
      `${environment.getApiVersionUrl('user/settings/regions')}`,
      { ...data },
    );
  }

  // Настройка отображения объявлений по категориям
  setListSelectedCategories(
    type: TAnnouncementType,
    data: TSettingsCategoriesRequest | [],
  ): Observable<TBooleanResponse> {
    return this.http.put<TBooleanResponse>(
      `${environment.getApiVersionUrl(`user/settings/categories/${type}`)}`,
      { ...data },
    );
  }

  // Выбрать все регионы
  selectAllRegions() {
    const newList = this.#listSelectedRegions().map((region) => ({
      ...region,
      is_selected: true,
    }));

    this.#listSelectedRegions.set(newList);
  }

  // Сбросить все регионы
  resetAllRegions() {
    const newList = this.#listSelectedRegions().map((region) => ({
      ...region,
      is_selected: false,
    }));

    this.#listSelectedRegions.set(newList);
  }

  // Переключение региона
  toogleRegion(id: number, newValue: boolean) {
    const newList = this.#listSelectedRegions().map((region) =>
      region.id === id
        ? {
            ...region,
            is_selected: newValue,
          }
        : region,
    );

    this.#listSelectedRegions.set(newList);
  }

  // Создание данных для запроса на изменение приоритетности регионов
  createRegionsRequestData(): TSettingsRegionsRequest {
    const idsList = this.#listSelectedRegions()
      .filter((region) => region.is_selected)
      .map((region) => region.id);

    return {
      region_ids: idsList,
      is_all_regions: false,
    };
  }

  // Переключение категории, культуры или классификации
  toogleCategoriesTreeEntity(ids: TToogleCategoriesTreeEntity): void {
    const category = this.#listSelectedCategories().find(
      (category) => category.code === ids.categoryId,
    );

    if (category) {
      // Переключение категории
      if (!ids.cerealId) {
        const newStatus =
          category.status !== 'selected' ? 'selected' : 'not_selected';

        if (category.cereals) {
          const cereals = category.cereals.map((cereal) => {
            if (cereal.classifications) {
              const classifications = cereal.classifications.map(
                (classification) => ({
                  ...classification,
                  status: newStatus,
                }),
              );

              cereal.classifications = classifications;
            }

            return { ...cereal, status: newStatus };
          });

          category.cereals = cereals;
        }

        category.status = newStatus;
      }

      // Переключение культуры или классификации
      if (ids.cerealId) {
        const cereal = category.cereals.find(
          (cereal) => cereal.id === ids.cerealId,
        );

        if (cereal) {
          // Переключение культуры
          if (!ids.classificationId) {
            const newCerealStatus =
              cereal.status !== 'selected' ? 'selected' : 'not_selected';

            if (cereal.classifications) {
              const classifications = cereal.classifications.map(
                (classification) => ({
                  ...classification,
                  status: newCerealStatus,
                }),
              );

              cereal.classifications = classifications;
            }

            cereal.status = newCerealStatus;
          }

          // Переключение классификации
          if (ids.classificationId) {
            const classifications = cereal.classifications.map(
              (classification) => {
                if (classification.code === ids.classificationId) {
                  const newStatus =
                    classification.status !== 'selected'
                      ? 'selected'
                      : 'not_selected';

                  return {
                    ...classification,
                    status: newStatus,
                  };
                } else {
                  return { ...classification };
                }
              },
            );

            cereal.classifications = classifications;

            const selectedClassification = cereal.classifications.filter(
              (classification) => classification.status === 'selected',
            );
            const getCerealStatus = () => {
              if (selectedClassification.length < 1) return 'not_selected';

              if (
                selectedClassification.length === cereal.classifications.length
              )
                return 'selected';

              return 'partly';
            };

            cereal.status = getCerealStatus();
          }
        }

        const selectedCereals = category.cereals.filter(
          (cereal) => cereal.status === 'selected',
        );
        const getCategoryStatus = () => {
          if (selectedCereals.length < 1) return 'not_selected';

          if (selectedCereals.length === category.cereals.length)
            return 'selected';

          return 'partly';
        };

        category.status = getCategoryStatus();
      }
    }

    return;
  }

  // Создание данных для запроса на изменение приоритетности категорий
  createCategoriesRequestData(): TSettingsCategoriesRequest {
    const idsList = this.#listSelectedCategories()
      .filter((category) => category.status !== 'not_selected')
      .map((category) => {
        if (category.cereals) {
          const cereals = category.cereals
            .filter((cereal) => cereal.status !== 'not_selected')
            .map((cereal) => {
              if (cereal.classifications) {
                const classifications = cereal.classifications
                  .filter(
                    (classfication) => classfication.status === 'selected',
                  )
                  .map((classification) => ({ code: classification.code }));

                return { id: cereal.id, classifications };
              } else {
                return {
                  id: cereal.id,
                };
              }
            });

          return { code: category.code, cereals };
        } else {
          return { code: category.code };
        }
      });

    return { categories: idsList };
  }
}
