import { HttpClient } from '@angular/common/http';
import {
  Injectable,
  computed,
  signal,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  catchError,
  forkJoin,
  Observable,
  shareReplay,
  tap,
  throwError,
} from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

import { ROUTE_HOME } from '@settings/routes';
import { StorageService } from './storage.sevice';
import { ProfileService } from './profile.service';
import { TStorageTokens } from '@models/commonTypes';
import { STORAGE_REDIRECT_PATH, STORAGE_TOKENS } from '@settings/constants';
import { TAuthFormData, TTokens } from '@models/authTypes';
import { NotificationService } from './notification.service';
import { TFormattedApiError } from '@utils/formattingApiError';
import { PriorityService } from '@components/pages/profile/profilePages/settings-profile/tabs/priority/core/priority.service';
import { TProfileData } from '@models/profileTypes';
import { SubscriptionService } from './subscription.service';

import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private profileService: ProfileService,
    private priorityService: PriorityService,
    private subscriptionService: SubscriptionService,
    private storageService: StorageService,
    private notificationService: NotificationService,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {}

  #isAuth = signal<boolean | undefined>(undefined);
  isAuth = computed(this.#isAuth);
  isKnownUser = computed(() => undefined !== this.isAuth());

  #loading = signal<boolean>(false);
  loading = computed(this.#loading);

  #loadingAuthData = signal<boolean>(true);
  loadingAuthData = computed(this.#loadingAuthData);

  #error = signal<string | null>(null);
  error = computed(this.#error);

  logIn(data: TAuthFormData): Observable<TTokens> {
    this.#loading.set(true);

    return this.http
      .post<TTokens>(
        `${environment.getApiVersionUrl('auth/signin')}`,
        {
          ...data,
          ...environment.getRequestData(),
        },
        {
          headers: {
            skipBearerAuthorization: 'true',
          },
        },
      )
      .pipe(
        tap((response) => {
          this.#error.set(null);
          this.afterLogIn(response);

          // Редирект на главную или по сохраненному пути
          const redirectPath = this.storageService.getItem<string>(
            STORAGE_REDIRECT_PATH,
          );
          this.router.navigate([redirectPath || ROUTE_HOME]).then(() => {
            if (redirectPath)
              this.storageService.clearItem(STORAGE_REDIRECT_PATH);
          });

          this.#loading.set(false);
        }),
        catchError((error: TFormattedApiError) => {
          this.#error.set(error.formattedErrorMessage);
          this.#loading.set(false);

          console.error(error);
          return throwError(() => error);
        }),
      );
  }

  logOut(): void {
    this.http
      .post(`${environment.getApiVersionUrl('auth/logout')}`, {})
      .subscribe({
        next: () => {
          this.#error.set(null);
          this.resetUserData();
          void this.router.navigate([ROUTE_HOME]);
        },

        error: (error: TFormattedApiError) => {
          this.#error.set(error.formattedErrorMessage);

          if (error.formattedErrorMessage)
            this.notificationService.error(error.formattedErrorMessage);
        },
      });
  }

  // Запрос на обновление токена
  tokenUpdate() {
    const token = this.storageService.getTokensItem(STORAGE_TOKENS);

    return this.http.post<TTokens>(
      `${environment.getApiVersionUrl('auth/refresh-token')}`,
      {
        refresh_token: token.refreshToken,
        ...environment.getRequestData(),
      },
      {
        headers: {
          skipBearerAuthorization: 'true',
        },
      },
    );
  }

  setTokens(data: TTokens): void {
    this.storageService.setItem<TStorageTokens>(STORAGE_TOKENS, {
      accessToken: data.tokens.access_token,
      refreshToken: data.tokens.refresh_token,
    });
  }

  getTokens(): TStorageTokens {
    return this.storageService.getTokensItem(STORAGE_TOKENS);
  }

  afterLogIn(newTokens: TTokens) {
    this.setTokens(newTokens);
    this.#isAuth.set(true);

    // Получение данных для авторизованного пользователя
    this.loadInitialAuthData();
  }

  // Проверка авторизованности пользователя
  checkAuth(): void {
    const token = this.storageService.getTokensItem(STORAGE_TOKENS);

    if (token.accessToken) {
      // Если есть токен - пробуем получить профиль
      this.checkAuthRequest().subscribe({
        next: () => {
          this.#error.set(null);
          this.#isAuth.set(true);
          this.loadInitialAuthData();
        },
        error: (error: TFormattedApiError) => {
          this.#error.set(error.formattedErrorMessage);

          // скорее всего мы и не авторизованы
          this.#isAuth.set(false);
          this.storageService.clearItem(STORAGE_TOKENS);

          console.error(error);
        },
      });
    } else {
      // сервер не знает статус юзера для показа блоков
      if (isPlatformBrowser(this.platformId)) {
        this.#isAuth.set(false);
      }
      this.storageService.clearItem(STORAGE_TOKENS);
    }
  }

  checkAuthRequest(): Observable<TProfileData> {
    return this.http
      .get<TProfileData>(`${environment.getApiVersionUrl('user/profile')}`)
      .pipe(
        tap((response) => {
          this.profileService.setProfileData(response);
        }),
        shareReplay(1),
      );
  }

  loadInitialAuthData(): void {
    this.priorityService.loadAllLists({ type: 'sell' });

    forkJoin([
      this.subscriptionService.loadSubscription(),
      this.profileService.loadProfile(),
    ]).subscribe({
      next: (response) => {
        this.#error.set(null);

        this.subscriptionService.setSubscription(response[0]);
        this.profileService.setProfileData(response[1]);

        this.#loadingAuthData.set(false);
        this.#loading.set(false);
      },
      error: (error: TFormattedApiError) => {
        this.#loadingAuthData.set(false);
        this.#loading.set(false);

        if (error.formattedErrorMessage)
          this.notificationService.error(error.formattedErrorMessage);
      },
    });
  }

  setError(newValue: string | null) {
    this.#error.set(newValue);
  }

  resetUserData() {
    this.#isAuth.set(false);
    this.storageService.clearItem(STORAGE_TOKENS);
    this.storageService.clearItem(STORAGE_REDIRECT_PATH);
    this.profileService.resetProfileData();
  }
}
